
export class AppConstant {
    public static DATE_FORMAT = 'dd-mm-yy';
    public static TITLE = 'PSGU';
    public static ORG_NAME = 'PSGU';
    public static DEFAULT_LOGIN: 'OTP' | 'PASSWORD' | 'BOTH' = 'PASSWORD';
    static LOGO_SRC = 'assets/images/logo.png';
    static ACCESS_DENIED_SVG = 'assets/images/access-denied.svg';
    static DEFAULT_PROFILE_URL = 'assets/images/avatar.png';
    static DEFAULT_GROUP_IMAGE_URL = 'assets/images/grp-avatar.png';
    static MAX_IMAGE_UPLOAD_SIZE = 1000000; //1mb
    static MAX_FILE_UPLOAD_SIZE = 2000000; // 2mb
    static MAX_VIDEO_UPLOAD_SIZE = 5000000; //5mb
    static DEFAULT_PROFILE_IMAGE = 'assets/images/blank-profile-picture.png';
    static DEFAULT_GROUP_IMAGE = 'assets/images/blank-profile-picture.png';
    static DEFAULT_OLD_IMAGE_APPEND = '[~@!OLDIMAGE!@~]';
    static DEFAULT_DATE_RANGE = '1940:' + new Date().getFullYear().toString();
    static DEFAULT_FUTURE_DATE_RANGE = '1960:' + (new Date().getFullYear() + 5).toString();
    public static GENDER = [
        { id: 1, genderName: 'Male' },
        { id: 2, genderName: 'Female' },
    ];
    public static TABLE_PAGE_ROWS = 10;
}
export const VALIDATOR_PATTERNS = {
    EMAIL: /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/,
    // MOBILE: /^[6-9]{1}[0-9]{9}$/,
    NUMBER: /^[0-9]+$/,
    POSITIVE_INTEGER: /\d+/,
    WHOLE_NUMBER: /^[1-9]\d*$/,
    NUMBER_FLOAT: /^[+-]?([0-9]*[.])?[0-9]+$/,
    TEXT: /^([a-zA-Z0-9+_+-]+\s)*[a-zA-Z0-9+_+-]+$/,
    TEXT_NOT_SPACE_IN_BETWEEN: /^(?=.{1,50}$)[a-zA-Z]+(?:['_.\\s][a-zA-Z]+)*$/,
    TEXT_ONLY: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
    IFC_CODE: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    PINCODE: /^[1-9][0-9]{5}$/,
    AADHAR_VALIDATOR: /^[2-9]{1}[0-9]{3}[0-9]{8}$/,
    MOBILE:/^[1-9]{1}[0-9]{3,14}$/,//international

    MUST_BE: function (s: number) { return new RegExp(`^.{${s}}$`) }
}
export const MODULE_NAME = {
    USER: 1,
    MASTER: 2,
    CUSTOMER: 3,
    PRODUCT: 4,
    EVENT: 5,
    DISTRIBUTION_CENTER: 6,
    ORDER: 7,
    VENDOR_ORDER: 8,
    REPORT: 9
}

export const PERMISSION = {
    ADD: 1,
    UPDATE: 2,
    DELETE: 3,
    READ: 4,
    VERIFY: 5,
    VIEW_ALL: 8,
    REJECT: 9,
    MANAGE_ROLE: 10,
    PROCESS: 11,
    MANAGE_EMAIL_TEMPLATES: 12,
    FLAT_REPORT: 13,
    APPROVE: 14,
    MANAGE_MASTER: 15,
    MEMBER_REPORT: 16,
    CONFIRM: 17,
    DELIVER: 18,
    PLACED_ORDER: 19,
    DISPATCH: 20,
    MANAGE_ALLIANCE_PARTNER: 21
}

export const RESPONSE_CODE = {
    SUCCESS: 200,
    VALIDATION_ERROR: 400,
    ERROR: 500,
    METHOD_ERROR: 405,
}
export const ALIVE_STATUS = [
    { id: 1, alive_statusName: 'Active', color: 'success' },
    { id: 2, alive_statusName: 'Died', color: 'danger' },
]
export const STATUS_CHILD = [
    { id: 1, childStatusName: 'Studying' },
    { id: 2, childStatusName: 'Not Studying' },
    { id: 3, childStatusName: 'Working' },
    { id: 4, childStatusName: 'Married' },
    { id: 5, childStatusName: 'Died' }
]
export const STATUS_TBL = {
    1: "Active",
    0: "In Active"
}

export const REFERENCE_MODULE = {
    ADMIN: 1,
    USER: 2,
    VENDOR: 3,
    ALLIANCE_PARTNER: 4,
    EVENT_MANAGER: 5,
    ORGANIZATION: 6
}