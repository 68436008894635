import { page, userPermission } from "../interface/user";
export const ACTION = {
    LIST: 'list',
    ADD: 'new',
    VIEW: 'view'
}
export class UrlServices {
    constructor() { }
    public static DASHBOARD_ROUTE = 'dashboard';
    public static ACCESS_DENIED_ROUTE = 'access_denied';
    public static AUTH_PAGE = {
        //USER_ROUTE :
        LOGIN_URL: '/' + 'login',
        FORGOT_PASSWORD_URL: '/' + 'forgotPassword',
        RESET_PASSWORD_URL: '/' + 'reset',
        LOGOUT_URL: '/' + 'logout',
        ACTIVATE: '/' + 'activate'
    }
    public static PAGE_URL: page = {
        EMAIL: {
            LIST: {
                URL: '/email' + '/' + ACTION.LIST,
                permission: {} as userPermission,
            },
            ADD: {
                URL: '/email' + '/' + ACTION.ADD,
                permission: {} as userPermission,
            },
            VIEW: {
                URL: '/email' + '/' + ACTION.VIEW,
                permission: {} as userPermission,
            }
        },
        USER: {
            LIST: {
                URL: '/user' + '/' + ACTION.LIST,
                permission: { moduleName: 'USER', actionName: 'VIEW_ALL' },
            },
            ADD: {
                URL: '/user' + '/' + ACTION.ADD,
                permission: { moduleName: 'USER', actionName: 'ADD' }
            },
            VIEW: {
                URL: '/user' + '/' + ACTION.VIEW,
                permission: { moduleName: 'USER', actionName: 'READ' }
            },
            ROLE: {
                URL: '/user' + '/roles-list',
                permission: { moduleName: 'USER', actionName: 'MANAGE_ROLE' }
            },
            ALLIANCE_PARTNER_LIST: {
                URL: '/user' + '/alliance-partner-' + ACTION.LIST,
                permission: { moduleName: 'USER', actionName: 'MANAGE_ALLIANCE_PARTNER' }
            },
            NEW_ALLIANCE_PARTNER: {
                URL: '/user' + '/alliance-partner-' + ACTION.ADD,
                permission: { moduleName: 'USER', actionName: 'MANAGE_ALLIANCE_PARTNER' }
            }
        },
        MASTER: {
            ADDRESS: {
                URL: '/master' + '/location',
                permission: { moduleName: 'MASTER', actionName: 'MANAGE_MASTER' }
            },
            OFFICE: {
                URL: '/master' + '/office',
                permission: { moduleName: 'MASTER', actionName: 'MANAGE_MASTER' }
            },
            GENERAL: {
                URL: '/master' + '/general',
                permission: { moduleName: 'MASTER', actionName: 'MANAGE_MASTER' }
            },
            EMAIL: {
                URL: '/master' + '/email',
                permission: { moduleName: 'MASTER', actionName: 'MANAGE_MASTER' }
            },
            UPDATE_EMAIL: {
                URL: '/master' + '/update-email',
                permission: { moduleName: 'MASTER', actionName: 'MANAGE_MASTER' }
            },
            MOBILE: {
                URL: '/master' + '/mobile-app',
                permission: { moduleName: 'MASTER', actionName: 'MANAGE_EMAIL_TEMPLATES' }
            },
        },
        CUSTOMER: {
            LIST: {
                URL: '/customer' + '/' + ACTION.LIST,
                permission: { moduleName: 'CUSTOMER', actionName: 'VIEW_ALL' }
            },
            NEW: {
                URL: '/customer' + '/' + ACTION.ADD,
                permission: { moduleName: 'CUSTOMER', actionName: 'ADD' }
            }
        },
        PRODUCT: {
            LIST: {
                URL: '/product' + '/' + ACTION.LIST,
                permission: { moduleName: 'PRODUCT', actionName: 'VIEW_ALL' }
            },
            NEW: {
                URL: '/product' + '/' + ACTION.ADD,
                permission: { moduleName: 'PRODUCT', actionName: 'ADD' }
            },
            VENDOR_LIST: {
                URL: '/product' + '/vendor' + ACTION.LIST,
                permission: { moduleName: 'PRODUCT', actionName: 'VIEW_ALL' }
            },
            NEW_VENDOR: {
                URL: '/product' + '/vendor' + ACTION.ADD,
                permission: { moduleName: 'PRODUCT', actionName: 'ADD' }
            },
        },
        EVENTS: {
            LIST: {
                URL: '/events' + '/' + ACTION.LIST,
                permission: { moduleName: 'EVENT', actionName: 'VIEW_ALL' }
            },
            NEW: {
                URL: '/events' + '/' + ACTION.ADD,
                permission: { moduleName: 'EVENT', actionName: 'ADD' }
            },
            LOCATION_LIST: {
                URL: '/events' + '/location' + ACTION.LIST,
                permission: { moduleName: 'DISTRIBUTION_CENTER', actionName: 'VIEW_ALL' }
            },
            NEW_LOCATION: {
                URL: '/events' + '/location' + ACTION.ADD,
                permission: { moduleName: 'DISTRIBUTION_CENTER', actionName: 'ADD' }
            },
        },
        ORDER: {
            LIST: {
                URL: '/order' + '/' + ACTION.LIST,
                permission: { moduleName: 'ORDER', actionName: 'VIEW_ALL' }
            },
            NEW: {
                URL: '/order' + '/' + ACTION.ADD,
                permission: { moduleName: 'ORDER', actionName: 'ADD' }
            },
            VENDOR_ORDER_LIST: {
                URL: '/order' + '/' + 'vendororder' + ACTION.LIST,
                permission: { moduleName: 'VENDOR_ORDER', actionName: 'VIEW_ALL' }
            },
            NEW_VENDOR_ORDER: {
                URL: '/order' + '/' + 'vendororder' + ACTION.ADD,
                permission: { moduleName: 'VENDOR_ORDER', actionName: 'ADD' }
            },
            ORDER_PAYMENT:{
                URL: '/order' + '/' + 'orderpayment' + ACTION.ADD,
                permission: { moduleName: 'VENDOR_ORDER', actionName: 'ADD' }

            }
        },
        REPORT: {
            CUSTOMER_FLAT_REPORT: {
                URL: '/report' + '/getAll',
                permission: { moduleName: 'VENDOR_ORDER', actionName: 'VIEW_ALL' }
            },
            ORDER_FLAT_REPORT: {
                URL: '/report' + '/getAll',
                permission: { moduleName: 'ORDER', actionName: 'VIEW_ALL' }
            },
            PAYMENT_REPORT:{
                URL: '/report' + '/payment',
                permission: { moduleName: 'ORDER', actionName: 'VIEW_ALL' }
            }
        },
    }
}
